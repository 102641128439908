var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "form_masuk"
    }
  }, [_vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Ubah Status ")]), _vm.$router.currentRoute.params.id ? _c('b-dropdown', {
    attrs: {
      "id": "status",
      "variant": _vm.formStatusVariant(_vm.form.schedule.form_status)
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.form.schedule.form_status || 'Tidak ada status')
          }
        })];
      },
      proxy: true
    }], null, false, 2060410950)
  }, [_vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' ? _c('b-dropdown-header', {
    attrs: {
      "id": "dropdown-header-label"
    }
  }, [_c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Ubah status ke ")])]) : _vm._e(), _vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' && _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-dropdown-item', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onUpdateStatus(true, _vm.form.schedule.form_status);
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'danger'
    }
  }, [_vm._v(" BATALKAN KEGIATAN ")])], 1) : _c('b-dropdown-item', {
    attrs: {
      "variant": "info"
    }
  }, [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v(" Tidak Dapat Mengubah Status yang Telah Dibatalkan. ")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.$router.currentRoute.params.id !== undefined ? _c('b-col', {
    staticClass: "text-center d-none d-sm-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('h1', [_vm._v("TBBM")])]) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "variant": "outline-secondary",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _c('span', [_vm._v("Kembali")])], 1)], 1)])], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('form-wizard', {
    key: "wizardKey",
    ref: "wizardFirst",
    class: _vm.isLastStep() ? _vm.dataID ? 'vertical-steps steps-transparent mb-3 sal-hide' : 'vertical-steps steps-transparent mb-3 sal' : 'vertical-steps steps-transparent mb-3 sal',
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "lazy": true,
      "layout": "vertical",
      "before-change": function beforeChange() {
        return true;
      },
      "finish-button-text": "Terbitkan Form Perencanaan Kedaatangan Kapal",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted,
      "on-change": _vm.onTabChange
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "wizard-footer-left"
        }, [props.activeTabIndex > 0 && !props.isLastStep ? _c('wizard-button', {
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.prevTab();
            }
          }
        }, [_vm._v("Previous")]) : _vm._e()], 1), _c('div', {
          staticClass: "wizard-footer-right"
        }, [!props.isLastStep ? _c('wizard-button', {
          staticClass: "wizard-footer-right",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.nextTab();
            }
          }
        }, [_vm._v("Next")]) : _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button",
          style: props.fillButtonStyle,
          attrs: {
            "disabled": _vm.inProgressTerbitkanForm
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.formSubmitted.apply(null, arguments);
            }
          }
        }, [_vm.inProgressTerbitkanForm ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(props.isLastStep ? 'Terbitkan Form Perencanaan Kedatangan Kapal' : 'Next') + " ")], 1)], 1)];
      }
    }])
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step1.title,
      "icon": _vm.wizard.first.step1.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step1.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step1.content.subtitle) + ".")])])])], 1), _c('validation-observer', {
    ref: "tbbmRulesMainBoat",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? 6 : 12,
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kapal *",
      "label-for": "vi-schedule-jenis_kapal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jenis Kapal",
      "vid": "Jenis Kapal",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-jenis_kapal",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jenis_kapals,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.isExistDetailID ? true : null,
            "label": "text",
            "placeholder": "Pilih Jenis Kapal",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.form.schedule.jenis_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "jenis_kapal", $$v);
            },
            expression: "form.schedule.jenis_kapal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.form.schedule.jenis_kapal && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PKK",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": "Ex: PKK.DN.IDSRI.2205.002444"
          },
          model: {
            value: _vm.form.schedule.no_pkk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pkk", $$v);
            },
            expression: "form.schedule.no_pkk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3477341837)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pemilik (1) *",
      "label-for": "vi-schedule-pemilik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " pemilik (1) *",
      "vid": "vi-schedule-pemilik",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-pemilik",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.pemilik,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "state": errors.length > 0 ? false : null,
            "disabled": _vm.isExistDetailID ? true : null,
            "label": "text",
            "placeholder": "Pilih Pemilik",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusPemilik();
            }
          },
          model: {
            value: _vm.form.schedule.pemilik,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "pemilik", $$v);
            },
            expression: "form.schedule.pemilik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3214350686)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pemilik (2)",
      "label-for": "vi-schedule-pemilik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pemilik (2)",
      "vid": "vi-schedule-pemilik",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-pemilik",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.pemilik.filter(function (data) {
              return data.value !== _vm.form.schedule.pemilik;
            }),
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.isExistDetailID ? true : null,
            "label": "text",
            "placeholder": "Pilih Pemilik",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusPemilik();
            }
          },
          model: {
            value: _vm.form.schedule.pemilik_dua,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "pemilik_dua", $$v);
            },
            expression: "form.schedule.pemilik_dua"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3487672737)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal ? _c('b-col', {
    attrs: {
      "md": _vm.form.schedule.kegiatan !== 'BUNKER' ? '6' : '12',
      "cols": _vm.form.schedule.kegiatan !== 'BUNKER' ? '6' : '12'
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kegiatan *",
      "label-for": "vi-schedule-kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Kegiatan",
      "vid": "vi-schedule-kegiatan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-kegiatan",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _vm.kegiatan : _vm.kegiatan.filter(function (data) {
              return data.value === 'TAMBAT' || data.value === 'BUNKER';
            }),
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenant(),
            "label": "text",
            "placeholder": "Pilih Kegiatan",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusKegiatan();
            }
          },
          model: {
            value: _vm.form.schedule.kegiatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan", $$v);
            },
            expression: "form.schedule.kegiatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 712657962)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tambah Kegiatan",
      "label-for": "vi-schedule-tambah-kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tambah Kegiatan",
      "vid": "vi-schedule-tambah-kegiatan",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-tambah-kegiatan",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.kegiatan.filter(function (data) {
              return data.value === 'BUNKER';
            }),
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null') && option.value.includes('BUNKER');
            },
            "disabled": _vm.permissionTenant() || _vm.form.schedule.jenis_kapal == 'Barge' ? true : false,
            "label": "text",
            "placeholder": "Tambah Kegiatan Bunker",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusKegiatan();
            }
          },
          model: {
            value: _vm.form.schedule.kegiatan_tambahan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_tambahan", $$v);
            },
            expression: "form.schedule.kegiatan_tambahan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1429133478)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.form.schedule.pemilik && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Kapal ".concat(_vm.isExistDetailID ? '( Hint: Dapat dicari dengan Nomor PKK ) *' : ''),
      "label-for": "vi-agen-name"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Boat Name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-boat-name",
            "suggestions": [{
              data: _vm.form.dm_river_boat.dm_river_boats
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Boat Name',
              disabled: _vm.isExistDetailID ? true : false
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_river_boat', 'boat_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_river_boat/',
                model: 'dm_river_boat',
                currentSuggest: 'boat_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var suggestion = _ref8.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.boat_name)[0] + Array.from(suggestion.item.boat_name)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.boat_name, " , Capt. ").concat(suggestion.item.captain_name))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("GT: ".concat(suggestion.item.gt, ", LOA: ").concat(suggestion.item.loa))
                }
              })])]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Telp: ".concat(suggestion.item.captain_telp, " , Created AT ").concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])])])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_river_boat.boat_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat, "boat_name", $$v);
            },
            expression: "form.dm_river_boat.boat_name"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_river_boatState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_river_boatState]) + " ")])])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3563600905)
  }), _vm.dm_river_boatStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "GT *",
      "label-for": "vi-dm_river_boat-gt"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "GT *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-gt",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.dm_river_boat.gt,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat, "gt", $$v);
            },
            expression: "form.dm_river_boat.gt"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 961084162)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LOA *",
      "label-for": "vi-dm_river_boat-loa"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "LOA *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "M"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-loa",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "78,12 (comma manual)"
          },
          model: {
            value: _vm.form.dm_river_boat.loa,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat, "loa", $$v);
            },
            expression: "form.dm_river_boat.loa"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 201311841)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Name *",
      "label-for": "vi-dm_river_boat-captain_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Capt. Name *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_river_boat-captain_name",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Full Capt. Name",
            "readonly": _vm.permissionTenantChild()
          },
          model: {
            value: _vm.form.dm_river_boat.captain_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat, "captain_name", $$v);
            },
            expression: "form.dm_river_boat.captain_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3559676123)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Telp *",
      "label-for": "vi-dm_river_boat-captain_telp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Capt. Telp *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-dm_river_boat-captain_telp",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "081145778613",
            "readonly": _vm.permissionTenantChild()
          },
          model: {
            value: _vm.form.dm_river_boat.captain_telp,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat, "captain_telp", $$v);
            },
            expression: "form.dm_river_boat.captain_telp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 721975869)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan Shipping Agent *",
      "label-for": "vi-dm_shipping_agent-shipping_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Shipping Agent *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_shipping_agent-shipping_name",
            "suggestions": [{
              data: _vm.form.dm_shipping_agent.dm_shipping_agents
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control form-input',
              placeholder: 'Cth: PT SARANA ABADI LESTARI',
              disabled: _vm.permissionTenant()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_shipping_agent', 'shipping_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_shipping_agent/',
                model: 'dm_shipping_agent',
                currentSuggest: 'shipping_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var suggestion = _ref14.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.shipping_name))])])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_shipping_agent.shipping_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_shipping_agent, "shipping_name", $$v);
            },
            expression: "form.dm_shipping_agent.shipping_name"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_shipping_agentState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_shipping_agentState]))])])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_shipping_agent.shipping_name ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'shipping_name' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 3912338025)
  }), _vm.dm_shipping_agentStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('hr'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveMainBoatDT();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan / Ubah Perencanaan')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1), _vm.permissionCreateTB() ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step2.title,
      "icon": _vm.wizard.first.step2.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step2.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step2.content.subtitle) + ".")])])])], 1), _c('validation-observer', {
    ref: "tbbmRulesTugBoat",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name Tug Boat *",
      "label-for": "vi-name_tug_boat"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Name Tug Boat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-name",
            "suggestions": [{
              data: _vm.handlingTugBoat()
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Boat Name',
              disabled: _vm.permissionSuggestionTugBoatMain()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'name_tug_boat');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'vw_tug_boat/TBBM',
                model: 'tug_boat',
                currentSuggest: 'name_tug_boat'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var suggestion = _ref16.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.name_tug_boat)[0] + Array.from(suggestion.item.name_tug_boat)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.name_tug_boat, ", ").concat(suggestion.item.no_pkk_tug_boat))
                }
              }), suggestion.item.tug_status === 'Tersedia' && suggestion.item.model_status === 'Tersedia' ? _c('div', [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s("Tersedia")
                }
              })])], 1) : suggestion.item.tug_status === 'Dalam Perencanaan' && suggestion.item.model_status === 'Tersedia' ? _c('div', [suggestion.item.tug_status === 'Dalam Perencanaan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Dalam Perencanaan' ? 'danger' : 'warning'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s("Sedang Direncanakan")
                }
              })]) : _vm._e()], 1) : _c('div', [suggestion.item.model_status === 'Sedang Digunakan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Sedang Digunakan' ? 'danger' : 'secondary'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s(suggestion.item.model_status)
                }
              })]) : _vm._e(), _c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(!suggestion.item.sedang_digunakan ? '' : " : ".concat(suggestion.item.sedang_digunakan)))
                }
              })], 1), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Updated AT ".concat(_vm.formatDateTime(suggestion.item.updated_at)))
                }
              })])])])])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "name_tug_boat", $$v);
            },
            expression: "form.tug_boat.name_tug_boat"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.tug_boatState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.tug_boatState]) + " ")])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.tug_boats.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.name_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'name_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 4179726651)
  }), _vm.tug_boatStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "GT ( Kapal ) *",
      "label-for": "vi-tug_boat-gt_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " GT ( Kapal ) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-gt_tug_boat",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.tug_boat.gt_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "gt_tug_boat", $$v);
            },
            expression: "form.tug_boat.gt_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 239610526)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LOA ( Kapal ) *",
      "label-for": "vi-tug_boat-loa_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " LOA ( Kapal ) *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "M"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-loa_tug_boat",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "78,12 (comma manual)"
          },
          model: {
            value: _vm.form.tug_boat.loa_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "loa_tug_boat", $$v);
            },
            expression: "form.tug_boat.loa_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 632946685)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Name *",
      "label-for": "vi-tug_boat-captain_name_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Name *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-captain_name_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Full Capt. Name",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_name_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_name_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2923326535)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Telp *",
      "label-for": "vi-tug_boat-captain_telp_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Telp *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-captain_telp_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "081145778613",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_telp_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_telp_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_telp_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1035077217)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Nomor PKK *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "Cth: PKK.DN.IDSRI.2208.000123"
          },
          model: {
            value: _vm.form.tug_boat.no_pkk_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "no_pkk_tug_boat", $$v);
            },
            expression: "form.tug_boat.no_pkk_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.no_pkk_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'no_pkk_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2984778096)
  })], 1)], 1), _vm.form.schedule.jenis_kapal !== 'Barge' ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan Shipping Agent *",
      "label-for": "vi-dm_shipping_agent-shipping_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Perusahaan Shipping Agent *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_shipping_agent-shipping_name",
            "get-suggestion-value": _vm.getSuggestionValue,
            "suggestions": [{
              data: _vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents || []
            }],
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control form-input',
              placeholder: 'Cth: PT SARANA ABADI LESTARI',
              disabled: _vm.permissionSuggestionTugBoat()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'dm_shipping_agent', 'dm_shipping_agent.shipping_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_shipping_agent/',
                model: 'tug_boat',
                currentSuggest: 'tug_boat',
                childSuggesst: 'dm_shipping_agent.shipping_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref23) {
              var suggestion = _ref23.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.shipping_name))])])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.dm_shipping_agent.shipping_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat.dm_shipping_agent, "shipping_name", $$v);
            },
            expression: "\n                          form.tug_boat.dm_shipping_agent.shipping_name\n                        "
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_shipping_agentState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_shipping_agentState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.dm_shipping_agent.shipping_name ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'shipping_name' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 3502781360)
  }), _vm.dm_shipping_agentStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Kapal *",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Kapal *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Asal Kapal',
              disabled: _vm.permissionTenant()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref25) {
              var suggestion = _ref25.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2878152044)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar Tug Boat *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar Tug Boat *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref26) {
        var errors = _ref26.errors;
        return [!_vm.permissionTenantAndSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar Tug Boat *",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_sandar", $$v);
            },
            expression: "form.tug_boat.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.tug_boat.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', ''),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2372539972)
  })], 1)], 1) : _vm._e(), !_vm.isExistDetailID && !_vm.form.tug_boat.isNew ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.breakSuggestionValueTB();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )')
    }
  })], 1), _c('span', [_c('small', [_c('strong', [_vm._v("Abaikan jika data master kapal yang otomatis teriisi sudah benar")])])])], 1)]) : _vm._e()], 1)], 1), !_vm.$can('manage', 'special') ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'security' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-danger",
      "block": "",
      "disabled": !_vm.form.tug_boat.no_pkk_tug_boat
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Duplicate data tug boat and Create as a new data")])], 1), _c('span', [_vm._v("Contoh kasus: ada pembaharuan nomor PKK")])], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'security' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-warning",
      "block": "",
      "disabled": !_vm.form.tug_boat.no_pkk_tug_boat
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Revisi data tug boat, Jika ada data yang tidak sesuai.")])], 1), _c('span', [_vm._v("Contoh kasus: kesalahan penulisan")])], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTB({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('')
    }
  })], 1)], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "success",
      "block": "",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.saveToUpdateDMKTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('SIMPAN')
    }
  })], 1)], 1) : _vm._e(), !_vm.$can('manage', 'special') ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'security' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && !_vm.statePayloadDMKTB && !_vm.statePayloadDMKTBMaster ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTBMaster({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Ganti/Pilih Kapal")])], 1) : _vm._e()], 1) : _vm._e(), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTBMaster({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span')], 1)], 1) : _vm._e(), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "success",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.jwtGantiKapalTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', [_vm._v("Simpan")])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && (_vm.form.schedule.kegiatan === 'BUNKER' || _vm.form.schedule.kegiatan_tambahan === 'BUNKER') ? _c('tab-content', {
    attrs: {
      "id": "ijin-bunker",
      "title": "Ijin Bunker",
      "icon": _vm.wizard.first.step4.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Document File Ijin Bunker")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step4.content.subtitle) + ".")])])])], 1), _vm.loadingFileStorage ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('validation-observer', {
    ref: "tbbmRulesFileBunker",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor Ijin Bunker",
      "label-for": "vi-schedule-no_ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Ijin Bunker",
      "vid": "Nomor Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref27) {
        var errors = _ref27.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ijin_bunker",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Nomor Ijin Bunker' : 'Nomor Ijin Bunker'
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker", $$v);
            },
            expression: "form.schedule.no_ijin_bunker"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1523617818)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume (Total Bunker)",
      "label-for": "vi-dm_river_boat-total_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Volume (Total Bunker) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref28) {
        var errors = _ref28.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-total_bunker",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.total_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "total_bunker", $$v);
            },
            expression: "form.schedule.total_bunker"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 453246378)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "vi-schedule-ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Ijin Bunker",
      "vid": "Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref29) {
        var errors = _ref29.errors;
        return [_c('BFormFile', {
          attrs: {
            "placeholder": "Choose a file Ijin Bunker or drop it here...",
            "drop-placeholder": "Drop file here...",
            "state": errors.length > 0 ? false : null,
            "accept": ".pdf, .jpeg, .jpg, .png",
            "no-drop": ""
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker_file,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker_file", $$v);
            },
            expression: "form.schedule.no_ijin_bunker_file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm._l(_vm.no_ijin_bunker_file, function (item, index) {
          return _c('app-timeline-item', {
            key: index
          }, [_c('div', {
            staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          }), _c('p', [_c('b-img', {
            staticClass: "mr-1",
            attrs: {
              "src": require('@/assets/images/icons/xls.png'),
              "height": "auto",
              "width": "20"
            }
          }), _c('span', {
            staticClass: "align-bottom"
          }, [_c('a', {
            staticStyle: {
              "color": "grey"
            },
            attrs: {
              "href": item.download_link,
              "target": "_blank",
              "rel": "noopener noreferrer"
            }
          }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
        })];
      }
    }], null, false, 2228769003)
  })], 1)], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveIjinBunker();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Ijin Bunker')
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Realisasi Bunker")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref30) {
        var errors = _ref30.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktBunkerID : _vm.dpWktBunker
          },
          on: {
            "on-change": _vm.onStartChangedpWktBunker
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_bunker", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_bunker"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_bunker",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_bunker),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 517651499)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref31) {
        var errors = _ref31.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktBunker.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktBunker
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_bunker", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_bunker"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_bunker",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_bunker),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2163366688)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') && _vm.$router.currentRoute.params.id ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiBunker();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Realisasi Bunker')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionCreate() && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step4.title,
      "icon": _vm.wizard.first.step4.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step4.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step4.content.subtitle) + ".")])])])], 1), _vm.loadingFileStorage ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('validation-observer', {
    ref: "tbbmRulesFile",
    attrs: {
      "tag": "form"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "File/Attachments/Document ( RKBM*, PBMBB, dan BL )",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref32) {
        var errors = _ref32.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-file-attachments",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "file",
            "hidden": ""
          },
          model: {
            value: _vm.filesCheck,
            callback: function callback($$v) {
              _vm.filesCheck = $$v;
            },
            expression: "filesCheck"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('DropZoneUpload', {
          key: _vm.isHaveFile.length,
          attrs: {
            "uri": _vm.BACKEND_URL,
            "update-i-d": _vm.form.form_chain_tbbm_id,
            "accept-file": "application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xlsx,.xls",
            "max-files": 3,
            "re-upload-title": "Re Upload Pengajuan RKBM*, PBMBB, dan BL (Max 3 File)",
            "is-have-file": _vm.isHaveFile,
            "modelName": 'form_chain_tbbm',
            "info": "Maksimal 3 Berkas RKBM*, PBMBB, dan BL"
          },
          on: {
            "callBack": _vm.dropZoneCallBack
          }
        })];
      }
    }], null, false, 2940330119)
  })], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionCreate() ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step3.title,
      "icon": _vm.wizard.first.step3.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step3.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step3.content.subtitle) + ".")])])])], 1), _c('validation-observer', {
    ref: "tbbmRulesSchedule",
    attrs: {
      "tag": "form"
    }
  }, [_vm.form.schedule.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck' ? _c('span', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.form.schedule.pemilik && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-row', [_vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor pindah",
      "label-for": "vi-schedule-no_pindah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor pindah",
      "vid": "Nomor pindah",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref33) {
        var errors = _ref33.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pindah",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_pindah,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah", $$v);
            },
            expression: "form.schedule.no_pindah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1724178614)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor RKBM *",
      "label-for": "vi-schedule-no_rkbm"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RKBM *",
      "vid": "Nomor RKBM *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref34) {
        var errors = _ref34.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rkbm",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Ex: SL004.IDSRI.0123.000416'
          },
          model: {
            value: _vm.form.schedule.no_rkbm,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rkbm", $$v);
            },
            expression: "form.schedule.no_rkbm"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2987248187)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PBMBB *",
      "label-for": "vi-schedule-no_pbmbb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PBMBB *",
      "vid": "Nomor PBMBB *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref35) {
        var errors = _ref35.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pbmbb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Nomor PBMBB'
          },
          model: {
            value: _vm.form.schedule.no_pbmbb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pbmbb", $$v);
            },
            expression: "form.schedule.no_pbmbb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3285640241)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": _vm.form.schedule.pemilik_dua ? '6' : '12',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "(1) Volume Muatan * ( ".concat(_vm.form.schedule.pemilik, " )"),
      "label-for": "vi-dm_river_boat-volume_muatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Volume Muatan *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref36) {
        var errors = _ref36.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-volume_muatan",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.volume_muatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "volume_muatan", $$v);
            },
            expression: "form.schedule.volume_muatan"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2864383658)
  })], 1)], 1), _vm.form.schedule.pemilik_dua ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "(2) Volume Muatan * ( ".concat(_vm.form.schedule.pemilik_dua, " )"),
      "label-for": "vi-dm_river_boat-volume_muatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Volume Muatan *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref37) {
        var errors = _ref37.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-volume_muatan",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.volume_muatan_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "volume_muatan_tb", $$v);
            },
            expression: "form.schedule.volume_muatan_tb"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1020440579)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Kapal *",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Kapal *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref38) {
        var errors = _ref38.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Asal Kapal',
              disabled: _vm.permissionTenant()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref39) {
              var suggestion = _ref39.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 1211906988)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref40) {
        var errors = _ref40.errors;
        return [!_vm.$can('manage', 'special') || _vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpRencanaSandarID : _vm.dpRencanaSandar
          },
          on: {
            "on-change": _vm.onStartChangedpRencanaSandar
          },
          model: {
            value: _vm.form.schedule.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "rencana_sandar", $$v);
            },
            expression: "form.schedule.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.schedule.rencana_sandar),
            "readonly": _vm.permissionTenant(),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 414753662)
  })], 1)], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('hr'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveScheduleDT();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan / Ubah Perencanaan')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [_c('b-row', {
    staticClass: "match-height"
  }, [_vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" " + _vm._s(this.form.dm_river_boat.boat_name) + " ")])])])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.form.schedule.pemilik && _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor pindah",
      "label-for": "vi-schedule-no_pindah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor pindah",
      "vid": "Nomor pindah",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref41) {
        var errors = _ref41.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pindah",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_pindah,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah", $$v);
            },
            expression: "form.schedule.no_pindah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1724178614)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor RKBM *",
      "label-for": "vi-schedule-no_rkbm"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RKBM *",
      "vid": "Nomor RKBM *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref42) {
        var errors = _ref42.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rkbm",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Ex: SL004.IDSRI.0123.000416'
          },
          model: {
            value: _vm.form.schedule.no_rkbm,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rkbm", $$v);
            },
            expression: "form.schedule.no_rkbm"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2987248187)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PBMBB *",
      "label-for": "vi-schedule-no_pbmbb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PBMBB *",
      "vid": "Nomor PBMBB *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref43) {
        var errors = _ref43.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pbmbb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Nomor PBMBB'
          },
          model: {
            value: _vm.form.schedule.no_pbmbb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pbmbb", $$v);
            },
            expression: "form.schedule.no_pbmbb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3285640241)
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" " + _vm._s(this.form.tug_boat.name_tug_boat) + " ")])])])]), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor pindah",
      "label-for": "vi-schedule-no_pindah_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor pindah",
      "vid": " Nomor pindah",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref44) {
        var errors = _ref44.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pindah_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_pindah_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah_tb", $$v);
            },
            expression: "form.schedule.no_pindah_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1157841366)
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  })])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": _vm.form.schedule.pemilik_dua ? '6' : '12'
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "(1) Volume Muatan * ( ".concat(_vm.form.schedule.pemilik, " )"),
      "label-for": "vi-dm_river_boat-volume_muatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Volume Muatan *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref45) {
        var errors = _ref45.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-volume_muatan",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.volume_muatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "volume_muatan", $$v);
            },
            expression: "form.schedule.volume_muatan"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2864383658)
  })], 1)], 1), _vm.form.schedule.pemilik_dua ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "(2) Volume Muatan * ( ".concat(_vm.form.schedule.pemilik_dua, " )"),
      "label-for": "vi-dm_river_boat-volume_muatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Volume Muatan *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref46) {
        var errors = _ref46.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-volume_muatan",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.volume_muatan_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "volume_muatan_tb", $$v);
            },
            expression: "form.schedule.volume_muatan_tb"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1020440579)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Kapal *",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Kapal *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref47) {
        var errors = _ref47.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Asal Kapal',
              disabled: _vm.permissionTenant()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref48) {
              var suggestion = _ref48.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 986337516)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref49) {
        var errors = _ref49.errors;
        return [!_vm.$can('manage', 'special') || _vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpRencanaSandarID : _vm.dpRencanaSandar
          },
          on: {
            "on-change": _vm.onStartChangedpRencanaSandar
          },
          model: {
            value: _vm.form.schedule.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "rencana_sandar", $$v);
            },
            expression: "form.schedule.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.schedule.rencana_sandar),
            "readonly": _vm.permissionTenant(),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 962387070)
  })], 1)], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('hr'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveScheduleDT();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan / Ubah Perencanaan')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionAdminTenant() || _vm.$store.state.app.roleName === 'jetty man' ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step2.title,
      "icon": _vm.wizard.second.step2.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step2.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step2.content.subtitle) + ".")])])])], 1), _c('validation-observer', {
    ref: "adminTenantRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Form Persetujuan dari Admin " + _vm._s(_vm.form.schedule.pemilik) + " ")]), _c('hr'), _c('b-row', [!!_vm.form.schedule.no_do_bl ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "vi-schedule-keterangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Keterangan *",
      "vid": "Keterangan *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref50) {
        var errors = _ref50.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-keterangan",
            "state": errors.length > 0 ? false : null,
            "placeholder": 'Keterangan Batalkan persetujuan form perencanaan *'
          },
          model: {
            value: _vm.form.schedule.keterangan_batal_approval,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "keterangan_batal_approval", $$v);
            },
            expression: "form.schedule.keterangan_batal_approval"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1280188698)
  })], 1)], 1) : _vm._e(), _vm.permissionAdminTenant() && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": ""
    }
  }, [_c('br'), _c('b-button', {
    attrs: {
      "variant": !_vm.form.schedule.no_do_bl ? 'gradient-primary' : 'gradient-danger',
      "disabled": _vm.loadingTemPatra
    },
    on: {
      "click": function click($event) {
        _vm.saveFormAdminTEMPATRA(!_vm.form.schedule.no_do_bl ? 'no_do_bl' : null);
        _vm.fetchData();
      }
    }
  }, [!_vm.loadingTemPatra ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": !_vm.form.schedule.no_do_bl ? 'CheckCircleIcon' : 'XCircleIcon'
    }
  }) : _vm._e(), _vm.loadingTemPatra ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s(!_vm.form.schedule.no_do_bl ? 'Setujui form perencanaan' : 'Batalkan persetujuan form perencanaan')
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _vm._v(" Approve By : " + _vm._s(_vm.form.schedule.approve_by) + " "), _c('br'), _vm._v(" Approve AT : " + _vm._s(_vm.formatDateTime(_vm.form.schedule.ijin_lengkap_time)) + " ")], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.importantToEDIT() ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step1.title,
      "icon": _vm.wizard.second.step1.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step1.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step1.content.subtitle) + ".")])])])], 1), !_vm.form.schedule.no_spog && !_vm.form.schedule.no_spog_tb && _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum diijinkan sandar oleh admin SAL ")], 1)]) : !_vm.form.schedule.no_spog && _vm.form.schedule.jenis_kapal !== 'Barge' && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum diijinkan sandar oleh admin SAL ")], 1)]) : !_vm.form.schedule.ijin_lengkap_time && _vm.form.schedule.kegiatan === 'BUNKER' && _vm.form.schedule.jenis_kapal !== 'Barge' ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum diijinkan sandar oleh admin SAL ")], 1)]) : _vm.form.schedule.no_do_bl === 'no_do_bl' && _vm.form.schedule.kegiatan === 'BUNKER' && _vm.form.schedule.jenis_kapal !== 'Barge' ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum diijinkan berkegiatan bunker ")], 1)]) : _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _vm._v(" Telah "), _c('strong', [_vm._v("diijinkan sandar")]), _vm._v(" oleh admin SAL ")], 1)]), _c('br'), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" Sandar & Berangkat ")]), _c('br'), _c('small', [_vm._v("Tabs Menu")])])];
      },
      proxy: true
    }], null, false, 2015253494)
  }, [_c('b-card', [_c('validation-observer', {
    ref: "refRKA1",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" Realisasi Kapal " + _vm._s(_vm.form.dm_river_boat.boat_name) + " ")])])])]) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Jetty",
      "label-for": "vi-schedule-position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Jetty",
      "vid": "Posisi Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref51) {
        var errors = _ref51.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && !_vm.$store.state.app.roleName === 'jetty man',
            "label": "text",
            "placeholder": "Pilih Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_jetty", $$v);
            },
            expression: "form.schedule.position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2706641724)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Baris Jetty",
      "label-for": "vi-schedule-position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Baris Jetty",
      "vid": "Posisi Baris Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref52) {
        var errors = _ref52.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && !_vm.$store.state.app.roleName === 'jetty man',
            "label": "text",
            "placeholder": "Pilih Posisi Baris Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_baris_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_baris_jetty", $$v);
            },
            expression: "form.schedule.position_baris_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3474693975)
  })], 1)], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-schedule-sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref53) {
        var errors = _ref53.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfigTB
          },
          on: {
            "on-change": _vm.onStartChangeTB
          },
          model: {
            value: _vm.form.schedule.sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "sandar_start_time", $$v);
            },
            expression: "form.schedule.sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.schedule.sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 817881636)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref54) {
        var errors = _ref54.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tujuan-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueTK,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Tujuan Kapal'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'schedule', 'tujuan_kapal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal',
                flagTujuanOps: 'tujuan_kapal_is_new'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref55) {
              var suggestion = _ref55.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.tujuan_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "tujuan_kapal", $$v);
            },
            expression: "form.schedule.tujuan_kapal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 3423032099)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-schedule-sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref56) {
        var errors = _ref56.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configsTB.end
          },
          on: {
            "on-change": _vm.onEndChangeTB
          },
          model: {
            value: _vm.form.schedule.sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "sandar_end_time", $$v);
            },
            expression: "form.schedule.sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.schedule.sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 916091082)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA1
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA1();
      }
    }
  }, [!_vm.loadingRelRKA1 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA1 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Sandar")])], 1) : _vm._e()], 1), _vm.isExistDetailID && (!_vm.form.schedule.position_jetty || !_vm.form.schedule.position_baris_jetty) ? _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum realisasi posisi jetty ")], 1)])], 1) : _vm._e()], 1)], 1)], 1), _c('b-card', [_c('validation-observer', {
    ref: "refRKA2",
    attrs: {
      "tag": "form"
    }
  }, [_vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-row', [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" Realisasi Kapal " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])])])]) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Jetty",
      "label-for": "vi-schedule-position_jetty_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Jetty",
      "vid": "Posisi Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref57) {
        var errors = _ref57.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty_tb",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && !_vm.$store.state.app.roleName === 'jetty man',
            "label": "text",
            "placeholder": "Pilih Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_jetty_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_jetty_tb", $$v);
            },
            expression: "form.schedule.position_jetty_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 521931836)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Baris Jetty",
      "label-for": "vi-schedule-position_jetty_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Baris Jetty",
      "vid": "Posisi Baris Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref58) {
        var errors = _ref58.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty_tb",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && !_vm.$store.state.app.roleName === 'jetty man',
            "label": "text",
            "placeholder": "Pilih Posisi Baris Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_baris_jetty_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_baris_jetty_tb", $$v);
            },
            expression: "form.schedule.position_baris_jetty_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2720962167)
  })], 1)], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-tug_boat-tug_sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref59) {
        var errors = _ref59.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_start_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2345165305)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref60) {
        var errors = _ref60.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tujuan-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueTKTB,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Tujuan Kapal'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'schedule', 'tujuan_kapal_tb');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal',
                flagTujuanOps: 'tujuan_kapal_tb_is_new'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref61) {
              var suggestion = _ref61.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.tujuan_kapal_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "tujuan_kapal_tb", $$v);
            },
            expression: "form.schedule.tujuan_kapal_tb"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 4038653628)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-tug_boat-tug_sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref62) {
        var errors = _ref62.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_end_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2014832663)
  })], 1)], 1) : _vm._e(), _vm.importantToEDIT() ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA2
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA2();
      }
    }
  }, [!_vm.loadingRelRKA2 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA2 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Sandar")])], 1) : _vm._e()], 1) : _vm._e(), _vm.isExistDetailID && (!_vm.form.schedule.position_jetty || !_vm.form.schedule.position_baris_jetty) ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mb-0 mt-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum realisasi posisi jetty ")], 1)])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "WatchIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" IDLE TIME ")]), _c('br'), _c('small', [_vm._v("Tabs Menu")])])];
      },
      proxy: true
    }], null, false, 2571445977)
  }, [_c('div', {
    staticClass: "tableidle"
  }, [_c('h2', [_vm._v(" Total Iddle Time : "), _c('span', {
    staticStyle: {
      "color": "grey"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.totalIdleTime))])])]), _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.idle.apiEndpoint,
      "title-tbl": "",
      "sub-title-tbl": "",
      "fields": _vm.idle.fields.filter(function (field) {
        return field.gone === true;
      }),
      "new-data-template": _vm.idleNewDataTemplate,
      "resolve-fetch-response": _vm.idleResolveFetchResponse,
      "resolve-fetch-url": _vm.idleResolveFetchUrl,
      "bottom-label": "",
      "bottom-icon": "TruckIcon",
      "info-page-ability": "Manage (All / JettyMan / Security)",
      "placeholder-search": "Idle Time"
    }
  })], 1), _c('div', {
    staticClass: "mb-1"
  })])], 1)], 1) : _vm._e(), _vm.dataID && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('tab-content', {
    attrs: {
      "title": "SPOG",
      "icon": "feather icon-unlock"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Document Realisasi SPOG")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Diisi oleh Admin SAL atau Agent ( Penerbit form ).")])])])], 1), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [_c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.dm_river_boat.boat_name)
          }
        })])];
      },
      proxy: true
    }], null, false, 3004739878)
  }, [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required_if: 'Waktu Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref63) {
        var errors = _ref63.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Nomor SPOG' : 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_spog,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog", $$v);
            },
            expression: "form.schedule.no_spog"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4136500073)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('BFormFile', {
    attrs: {
      "placeholder": "Choose a file SPOG or drop it here...",
      "drop-placeholder": "Drop file here...",
      "accept": ".pdf, .jpeg, .jpg, .png",
      "no-drop": ""
    },
    model: {
      value: _vm.form.schedule.no_spog_file,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "no_spog_file", $$v);
      },
      expression: "form.schedule.no_spog_file"
    }
  }), _vm._l(_vm.no_spog_file, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": "".concat(_vm.BACKEND_URL).concat(item.path, "/").concat(item.unique_name),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
  })], 2) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": !_vm.form.schedule.no_rpkro ? 'warning' : 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body",
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro);
      }
    }
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s(!_vm.form.schedule.no_rpkro ? 'Nomor RPKRO Belum terbit' : "Nomor RPKRO : ".concat(_vm.form.schedule.no_rpkro))
    }
  })], 1)])]), _vm.form.schedule.no_rpkro && _vm.isExistDetailID && !_vm.permissionTenant() && _vm.hideAction() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan('SPOG');
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.tug_boat.name_tug_boat)
          }
        })])];
      },
      proxy: true
    }], null, false, 3672481045)
  }, [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG",
      "label-for": "vi-schedule-no_spog_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required_if: 'Waktu Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref64) {
        var errors = _ref64.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Nomor SPOG' : 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_spog_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb", $$v);
            },
            expression: "form.schedule.no_spog_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 35779945)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('BFormFile', {
    attrs: {
      "placeholder": "Choose a file SPOG or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": ".pdf, .jpeg, .jpg, .png"
    },
    model: {
      value: _vm.form.schedule.no_spog_file_tb,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "no_spog_file_tb", $$v);
      },
      expression: "form.schedule.no_spog_file_tb"
    }
  }), _vm._l(_vm.no_spog_file_tb, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": "".concat(_vm.BACKEND_URL).concat(item.path, "/").concat(item.unique_name),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
  })], 2) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": !_vm.form.schedule.no_rpkro_tb ? 'warning' : 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body",
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro_tb);
      }
    }
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s(!_vm.form.schedule.no_rpkro_tb ? 'Nomor RPKRO Belum terbit' : "Nomor RPKRO : ".concat(_vm.form.schedule.no_rpkro_tb))
    }
  })], 1)])]), _vm.form.schedule.no_rpkro_tb && _vm.isExistDetailID && !_vm.permissionTenant() && _vm.hideAction() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatanSec
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatanSec('SPOG');
      }
    }
  }, [!_vm.loadingRealisasiKegiatanSec ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatanSec ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1) : _c('span', [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required_if: 'Waktu Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref65) {
        var errors = _ref65.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Diisi Admin SAL atau Agent' : 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_spog,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog", $$v);
            },
            expression: "form.schedule.no_spog"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3535177956)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('BFormFile', {
    attrs: {
      "placeholder": "Choose a file SPOG or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": ".pdf, .jpeg, .jpg, .png"
    },
    model: {
      value: _vm.form.schedule.no_spog_file,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "no_spog_file", $$v);
      },
      expression: "form.schedule.no_spog_file"
    }
  }), _vm._l(_vm.no_spog_file, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": "".concat(_vm.BACKEND_URL).concat(item.path, "/").concat(item.unique_name),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
  })], 2) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": !_vm.form.schedule.no_rpkro ? 'warning' : 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body",
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro);
      }
    }
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s(!_vm.form.schedule.no_rpkro ? 'Nomor RPKRO Belum terbit' : "Nomor RPKRO : ".concat(_vm.form.schedule.no_rpkro))
    }
  })], 1)])]), _vm.form.schedule.no_rpkro && _vm.isExistDetailID && !_vm.permissionTenant() && _vm.hideAction() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan('SPOG');
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.permissionSalOnly() ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step3.title,
      "icon": _vm.wizard.second.step3.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step3.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step3.content.subtitle) + ".")])])])], 1), _vm.isNoRpkro ? _c('div', [_c('hr'), _c('b-button', {
    attrs: {
      "variant": _vm.form.schedule.no_do_bl !== 'no_do_bl_sal' ? 'gradient-success' : 'gradient-danger',
      "disabled": _vm.loadingTemPatra
    },
    on: {
      "click": function click($event) {
        _vm.saveFormAdminSALBerkegiatanBunker(_vm.form.schedule.no_do_bl !== 'no_do_bl_sal' ? 'no_do_bl_sal' : 'no_do_bl');
        _vm.fetchData();
      }
    }
  }, [!_vm.loadingTemPatra ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": _vm.form.schedule.no_do_bl !== 'no_do_bl_sal' ? 'CheckCircleIcon' : 'XCircleIcon'
    }
  }) : _vm._e(), _vm.loadingTemPatra ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s(_vm.form.schedule.no_do_bl !== 'no_do_bl_sal' ? 'Setujui berkegiatan bunker' : 'Batalkan persetujuan berkegiatan bunker')
    }
  })], 1), _c('hr')], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [_c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.dm_river_boat.boat_name)
          }
        })])];
      },
      proxy: true
    }], null, false, 3004739878)
  }, [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Form Persetujuan Sandar dari Admin SAL ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor RPKRO *",
      "label-for": "vi-schedule-no_rpkro"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO *",
      "vid": "Nomor RPKRO *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref66) {
        var errors = _ref66.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor RPKRO *'
          },
          model: {
            value: _vm.form.schedule.no_rpkro,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro", $$v);
            },
            expression: "form.schedule.no_rpkro"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4273689028)
  })], 1), _vm._v(" Approve AT : " + _vm._s(_vm.formatDateTime(_vm.form.schedule.disetujui_time)) + " "), _c('hr')], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PPK *",
      "label-for": "vi-schedule-no_ppk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PPK *",
      "vid": "Nomor PPK *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref67) {
        var errors = _ref67.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ppk",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor PPK *'
          },
          model: {
            value: _vm.form.schedule.no_ppk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ppk", $$v);
            },
            expression: "form.schedule.no_ppk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2585918043)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref68) {
        var errors = _ref68.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktSandarIDP1 : _vm.dpWktSandarP1
          },
          on: {
            "on-change": _vm.onStartChangedpWktSandarP1
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_p1", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_p1"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 371965312)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref69) {
        var errors = _ref69.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktSandarP1.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktSandarP1
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_p1", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_p1"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2725435908)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [(_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && _vm.form.schedule.pemilik_dua ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik_dua) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref70) {
        var errors = _ref70.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktSandarID : _vm.dpWktSandar
          },
          on: {
            "on-change": _vm.onStartChangedpWktSandar
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_p2", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_p2"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2101455298)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref71) {
        var errors = _ref71.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktSandar.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktSandar
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_p2", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_p2"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1137248903)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.hideAction() ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan(null);
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.tug_boat.name_tug_boat)
          }
        })])];
      },
      proxy: true
    }], null, false, 3672481045)
  }, [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Form Persetujuan Sandar dari Admin SAL ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor RPKRO *",
      "label-for": "vi-schedule-no_rpkro_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO *",
      "vid": "Nomor RPKRO *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref72) {
        var errors = _ref72.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor RPKRO *'
          },
          model: {
            value: _vm.form.schedule.no_rpkro_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro_tb", $$v);
            },
            expression: "form.schedule.no_rpkro_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1345411172)
  })], 1), _vm._v(" Approve AT : " + _vm._s(_vm.formatDateTime(_vm.form.schedule.disetujui_time_tb)) + " "), _c('hr')], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PPK *",
      "label-for": "vi-schedule-no_ppk_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PPK *",
      "vid": "Nomor PPK *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref73) {
        var errors = _ref73.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ppk_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor PPK *'
          },
          model: {
            value: _vm.form.schedule.no_ppk_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ppk_tb", $$v);
            },
            expression: "form.schedule.no_ppk_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 299957723)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_tb_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref74) {
        var errors = _ref74.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktSandarTBID : _vm.dpWktSandarTB
          },
          on: {
            "on-change": _vm.onStartChangedpWktSandarTB
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_tb_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_tb_p1", $$v);
            },
            expression: "\n                                  form.schedule.kegiatan_start_time_tb_p1\n                                "
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_tb_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_tb_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1739910878)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_tb_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref75) {
        var errors = _ref75.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktSandarTB.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktSandarTB
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_tb_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_tb_p1", $$v);
            },
            expression: "\n                                  form.schedule.kegiatan_end_time_tb_p1\n                                "
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_tb_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_tb_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1311524781)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [(_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && _vm.form.schedule.pemilik_dua ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik_dua) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_tb_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref76) {
        var errors = _ref76.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktSandarTBIDP1 : _vm.dpWktSandarTBP1
          },
          on: {
            "on-change": _vm.onStartChangedpWktSandarTBP1
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_tb_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_tb_p2", $$v);
            },
            expression: "\n                                  form.schedule.kegiatan_start_time_tb_p2\n                                "
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_tb_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_tb_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 758788380)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_tb_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref77) {
        var errors = _ref77.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktSandarTBP1.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktSandarTBP1
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_tb_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_tb_p2", $$v);
            },
            expression: "\n                                  form.schedule.kegiatan_end_time_tb_p2\n                                "
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_tb_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_tb_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3406294894)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.hideAction() ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatanSec
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatanSec(null);
      }
    }
  }, [!_vm.loadingRealisasiKegiatanSec ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatanSec ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _c('span', [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Form Persetujuan Sandar dari Admin SAL")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor RPKRO *",
      "label-for": "vi-schedule-no_rpkro"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO *",
      "vid": "Nomor RPKRO *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref78) {
        var errors = _ref78.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor RPKRO *'
          },
          model: {
            value: _vm.form.schedule.no_rpkro,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro", $$v);
            },
            expression: "form.schedule.no_rpkro"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3733781956)
  })], 1), _vm._v(" Approve AT : " + _vm._s(_vm.formatDateTime(_vm.form.schedule.disetujui_time)) + " "), _c('hr')], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PPK *",
      "label-for": "vi-schedule-no_ppk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PPK *",
      "vid": "Nomor PPK *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref79) {
        var errors = _ref79.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ppk",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": 'Nomor PPK *'
          },
          model: {
            value: _vm.form.schedule.no_ppk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ppk", $$v);
            },
            expression: "form.schedule.no_ppk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3478529627)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref80) {
        var errors = _ref80.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_p1", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_p1"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3469308361)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_p1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref81) {
        var errors = _ref81.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_p1,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_p1", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_p1"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_p1",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p1),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1967065862)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [(_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && _vm.form.schedule.pemilik_dua ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" Realisasi untuk Pemilik " + _vm._s(_vm.form.schedule.pemilik_dua) + " ")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref82) {
        var errors = _ref82.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_p2", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_p2"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 26535178)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_p2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref83) {
        var errors = _ref83.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Diisi Admin SAL",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_p2,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_p2", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_p2"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_p2",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_p2),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "Diisi Admin SAL"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4144116677)
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.hideAction() ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan(null);
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e()], 1)], 1), _vm.$router.currentRoute.params.id !== undefined ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "to": {
        name: 'dashboard-monitoring-rrv-tbbm-summary',
        params: {
          id: _vm.form.form_chain_tbbm_id,
          api: 'form_chain_tbbm',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EyeIcon"
    }
  }), _vm._v(" Summary ")], 1), _c('hr'), _c('b-badge', {
    staticClass: "mr-1 mb-1 text-center",
    attrs: {
      "href": "#",
      "pill": "",
      "variant": "secondary"
    }
  }, [_c('strong', [_c('span', {
    domProps: {
      "textContent": _vm._s("Form Perencanaan Created BY ".concat(_vm.form.created_by))
    }
  })]), _c('br')]), _c('br'), _vm._v(" Created AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.created_at)) + " - Last Updated AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.updated_at)) + " ")], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }